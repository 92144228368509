import { AuthenticatedApi, Api } from '../utils/Api';
import { SP_AUTH_TOKEN } from '../utils/Constant';
import { Storage } from '../utils/Storage';

export const AuthService = {
  async getAuthenticatedUser() {
    if (!Storage.get(SP_AUTH_TOKEN)) {
      throw new Error('Auth token not found');
    }
    const { data } = await AuthenticatedApi.get('/api/1.0/user/get-authenticated');
    return data;
  },
  async loginWithOneTimeToken(oneTimeLoginToken) {
    const { data } = await Api.post('/api/1.0/auth/login/with-one-time-login-token', {
      oneTimeLoginToken,
    });
    return data;
  },
};
