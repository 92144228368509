import React from 'react';
import styled from '@emotion/styled';

const CenterFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export function CenterInPage({ children, ...rest }) {
  return <CenterFlex {...rest}>{children}</CenterFlex>;
}
