/* eslint-disable react-hooks/rules-of-hooks */
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { Suspense, useEffect, useMemo } from 'react';
import './styles/global-styles.scss';
import { Loader } from './components/Loader';
import { CenterInPage } from './components/CenterInPage';
import axios from 'axios';
import { useAuthCheck } from './utils/useAuthCheck';
import { accountActions } from './state/accountState';
import { useGlobalState } from './hooks/useGlobalState';
import { RetailService } from './services/RetailPos.service';
import { IntegrationService } from './services/Integration.service';
import { useNavigateWithQuery } from './components/useNavigateWithQuery';
import * as Sentry from '@sentry/react';
import { mappingActions } from './state/mappingState';

const SSOPage = React.lazy(() => import('./pages/sso-page/index'));
const ConnectDashboard = React.lazy(() => import('./pages/connect-dashboard/index'));
const CategoriesMaping = React.lazy(() => import('./pages/integrations/index'));
const FirstCategoriesMaping = React.lazy(() => import('./pages/integrations/categories-maping/FirstCategoriesMaping'));
const VendorMaping = React.lazy(() => import('./pages/integrations/vendor-maping/VendorMaping'));
const Summary = React.lazy(() => import('./pages/summary'));
const OauthPage = React.lazy(() => import('./pages/oauth-page/index'));

function RedirectToConnectorOauth() {
  const location = useLocation();
  const isSSOPage = useMemo(
    () => location.pathname.includes('/auth/sso') || location.pathname.includes('/process-oauth'),
    [location.pathname]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isSSOPage) return;
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const ssoUrl = await axios.get(
        `oauth/enterprise-bevspot/get-auth?retailId=${searchParams.get(
          'retailerId'
        )}&email=${searchParams.get('emailId')}&jwtToken=${searchParams.get('jwt_token')}`
      );
      const redirectUrl = new URL(ssoUrl.data);
      navigate({
        pathname: redirectUrl.pathname,
        search: `${redirectUrl.search}&retailerId=${searchParams.get(
          'retailerId'
        )}&emailId=${searchParams.get('emailId')}&jwt_token=${searchParams.get('jwt_token')}&timezone=${searchParams.get('timezone')}`,
      });
    })();
  }, []);
  return (
    <CenterInPage>
      <span>Authenticating</span>
      <Loader showAccentColor />
    </CenterInPage>
  );
}
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const location = useLocation();
  const navigate = useNavigateWithQuery();
  const isSSOPage = React.useMemo(
    () => location.pathname.includes('/auth/sso') || location.pathname.includes('/process-oauth'),
    [location.pathname]
  );
  const {
    accounts: { accountingAccount },
    mapping,
    auth: { isTokenValid, loading },
    dispatch,
  } = useGlobalState();

  useAuthCheck();
  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const requiredId = searchParams.get('retailerId');
      if (!isTokenValid || isSSOPage) return;
      try {
        dispatch(accountActions.setLoadingAccounts(true));
        const retailAccounts = await RetailService.getAccounts();
        const retailAccount =
          retailAccounts.length > 0
            ? retailAccounts.filter((retailAccount) => retailAccount.externalId === requiredId)[0]
            : null;
        dispatch(accountActions.setRetailAccount(retailAccount));
        const integration = await IntegrationService.getIntegrations(retailAccount.id);
        dispatch(accountActions.setIntegration(integration || null));
        dispatch(accountActions.setSourceAccountingAccount(integration?.sourceAccountingAccountId || null));
        const state = await IntegrationService.getState(retailAccount.id);
        const bevspotCategoryData = await RetailService.getCategories(retailAccount.name);
        dispatch(mappingActions.setMapping({
          ...mapping,
          dashboardCategoryMapping:
          bevspotCategoryData
        } || null))
        dispatch(accountActions.setAccountingAccount(state?.progress?.QBOAccountId || null));
        dispatch(accountActions.setSyncFrequency(state?.progress?.SyncFrequency || null));
        // if (integration) navigate('/summary');
        // else if (accountingAccount) navigate('integrations/1');
        dispatch(accountActions.setLoadingAccounts(false));
      } catch (error) {
        console.error(error);
        dispatch(accountActions.setLoadingAccounts(false));
      }
    })();
  }, [isTokenValid]);

  if (loading) {
    return (
      <CenterInPage>
        <h1>Authenticating</h1>
        <Loader showAccentColor />
      </CenterInPage>
    );
  }

  if (isTokenValid || isSSOPage) {
    return (
      <Suspense
        fallback={
          <CenterInPage>
            <Loader showAccentColor />
            <h1>Loading profile...</h1>
          </CenterInPage>
        }
      >
        <SentryRoutes>
          <Route path="/auth/sso" element={<SSOPage />} />
          <Route path="/" element={<ConnectDashboard />} />
          <Route path="/process-oauth" element={<OauthPage />} />
          <Route path="/integrations/1" element={<FirstCategoriesMaping />} />
          <Route path="/integrations/2" element={<CategoriesMaping />} />
          <Route path="/integrations/3" element={<VendorMaping />} />
          <Route path="/summary" element={<Summary />} />
        </SentryRoutes>
      </Suspense>
    );
  }
  if (!isTokenValid || !isSSOPage || !loading) return <RedirectToConnectorOauth />;
}

export default App;
