export const mappingInitialState = {
  categoryMapping: [],
  classMapping: [],
  cogsCategoryMapping: [],
  firstCategoryMapping: [],
  classCategoryMapping: [],
  dashboardCategoryMapping: [],
  vendorMapping: [],
  syncFrequency: null,
  syncTime: null,
  timeZone: null,
  
};
export const mappingReducer = (state, action) => {
  switch (action.type) {
    case 'mapping/SET_MAPPING': {
      return {
        categoryMapping: action.payload.mapping.categoryMapping,
        classMapping: action.payload.mapping.classMapping,
        cogsCategoryMapping: action.payload.mapping.cogsCategoryMapping,
        firstCategoryMapping: action.payload.mapping.firstCategoryMapping,
        classCategoryMapping: action.payload.mapping.classCategoryMapping,
        dashboardCategoryMapping: action.payload.mapping.dashboardCategoryMapping,
        vendorMapping: action.payload.mapping.vendorMapping,
        syncFrequency:action.payload.mapping.syncFrequency,
        syncTime:action.payload.mapping.syncTime,
        timeZone:action.payload.mapping.timeZone,

      };
    }
    default:
      return state;
  }
};

const setMapping = (mapping) => {
  return {
    type: 'mapping/SET_MAPPING',
    payload: {
      mapping,
    },
  };
};

export const mappingActions = {
  setMapping,
};
