import React, { useReducer, useContext } from 'react';
import { accountsReducer, accountsReducerInitialState } from '../state/accountState';
import { authReducer, authReducerInitialState } from '../state/authState';
import { mappingInitialState, mappingReducer } from '../state/mappingState';

const combineReducers = (slices) => (state, action) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );

const initialState = {
  accounts: accountsReducerInitialState,
  auth: authReducerInitialState,
  mapping: mappingInitialState,
};

const combinedReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  mapping: mappingReducer,
  test: (state, action) => {
    if (process.env.REACT_ENV) {
      // eslint-disable-next-line no-console
      console.log(action);
    }
    return state;
  },
});

export const StateContext = React.createContext(initialState);

export const StateProvider = ({ children, initialValue = undefined }) => {
  const [state, dispatch] = useReducer(combinedReducer, initialValue || initialState);

  return (
    <StateContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useGlobalState = () => useContext(StateContext);
