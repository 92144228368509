import * as Sentry from "@sentry/react";

export const accountsReducerInitialState = {
  loadingAccounts: false,
  accountingAccount: null,
  sourceAccountingAccount: null,
  retailAccount: null,
  integration: null,
  syncFrequency: null,
  systemType: null,
};
export function accountsReducer(state, action) {
  switch (action.type) {
    case "accounts/SET_ACCOUNTING_ACCOUNT":
      Sentry.setContext("accountingAccount", action.payload.accountingAccount);

      return {
        ...state,
        accountingAccount: action.payload.accountingAccount,
      };
      
    case 'accounts/SET_SOURCE_ACCOUNTING_ACCOUNT':
        Sentry.setContext('accountingAccount', action.payload.sourceAccountingAccount);
        return {
          ...state,
          sourceAccountingAccount: action.payload.sourceAccountingAccount,
        };
  
    case "accounts/SET_SYNC_FREQUENCY":
      Sentry.setContext("syncFrequency", action.payload.syncFrequency);

      return {
        ...state,
        syncFrequency: action.payload.syncFrequency,
      };

    case "accounts/SET_SYSTEM_TYPE":
        Sentry.setContext("systemType", action.payload.systemType);
  
        return {
          ...state,
          systemType: action.payload.systemType,
        };

    case "accounts/SET_RETAIL_ACCOUNT":
      Sentry.setContext("retailAccount", action.payload.retailAccount);
    
      return {
        ...state,
        retailAccount: action.payload.retailAccount,
      };
    case "accounts/SET_LOADING_ACCOUNTS":
      return {
        ...state,
        loadingAccounts: action.payload.loadingAccounts,
      };
    case "accounts/SET_INTEGRATION":
      Sentry.setContext("setIntegration", action.payload.integration);

      return {
        ...state,
        integration: action.payload.integration,
      };
    default:
      return state;
  }
}

const setLoadingAccounts = (loading) => {
  return {
    type: "accounts/SET_LOADING_ACCOUNTS",
    payload: {
      loadingAccounts: loading,
    },
  };
};

const setAccountingAccount = (accountingAccount) => {
  return {
    type: "accounts/SET_ACCOUNTING_ACCOUNT",
    payload: {
      accountingAccount,
    },
  };
};
const setSourceAccountingAccount = (sourceAccountingAccount) => {
  return {
    type: 'accounts/SET_SOURCE_ACCOUNTING_ACCOUNT',
    payload: {
      sourceAccountingAccount,
    },
  };
};

const setSyncFrequency = (syncFrequency) => {
  return {
    type: "accounts/SET_SYNC_FREQUENCY",
    payload: {
      syncFrequency,
    },
  };
};

const setSystemType = (systemType) => {
  return {
    type: "accounts/SET_SYSTEM_TYPE",
    payload: {
      systemType,
    },
  };
};
const setRetailAccount = (retailAccount) => {
  return {
    type: "accounts/SET_RETAIL_ACCOUNT",
    payload: {
      retailAccount,
    },
  };
};

const setIntegration = (integration) => {
  return {
    type: "accounts/SET_INTEGRATION",
    payload: {
      integration,
    },
  };
};

export const accountActions = {
  setLoadingAccounts,
  setAccountingAccount,
  setSourceAccountingAccount,
  setRetailAccount,
  setIntegration,
  setSyncFrequency,
  setSystemType,
};
