export const SP_AUTH_TOKEN='auth_token';

export const CONNECT_TO_QBO_CLICK = 'connect_to_qbo_click';

export const OAUTH_SUCCESS = 'Oauth_success';

export const OAUTH_FAILURE = 'Oauth_failure';

export const CATEGORY_MAPPING_COMPLETED = 'category_mapping_completed';
export const CATEGORY_MAPPING_FAILED = 'category_mapping_failed';


export const COGS_SKIPPED = 'Cogs_skipped';

export const COGS_MAPPING_COMPLETED = 'Cogs_mapping_completed';

export const VENDOR_MAPPING_COMPLETED_SYNC_ENABLED = 'Vendor_mapping_completed_sync_enabled';

export const CLICKED_DISCONNECT = 'clicked_disconnect';

export const DISCONNECTED_INTEGRATION = 'disconnected_integration';

export const CLICKED_EDIT_MAPPING = 'clicked_edit_mapping';

export const ERROR_GENERATED = 'error_generated';