import { AuthenticatedApi } from "../utils/Api";

export const IntegrationService = {
  async createIntegration(data) {
    const { data: response } = await AuthenticatedApi.post(
      '/api/1.0/retailpos-accounting-integration/integrations',
      data
    );
    return response;
  },

  async getIntegrations(id) {
    const { data: response } = await AuthenticatedApi.get(
      '/api/1.0/retailpos-accounting-integration/integrations?retailPosAccountId=' + id
    );
    return response;
  },

  async getState(retailPosAccountId) {
    const { data: response } = await AuthenticatedApi.get(
      '/api/1.0/retailpos-accounting-integration/states?retailPosAccountId=' + retailPosAccountId
    );
    return response;
  },

  async setState(state, retailPosAccountId) {
    const { data: response } = await AuthenticatedApi.post(
      '/api/1.0/retailpos-accounting-integration/states',
      { ...state, retailPosAccountId }
    );
    return response;
  },
  async resetState(state, retailPosAccountId) {
    const { data } = await AuthenticatedApi.post(
      `/api/1.0/retailpos-accounting-integration/states/reset-progress`,
      {
        ...state,
        retailPosAccountId,
      }
    );
    return data;
  },
  async deactivateIntegrations(retailPosAccountId) {
    const { data } = await AuthenticatedApi.post(
      '/api/1.0/retailpos-accounting-integration/deactivate-integrations?retailPosAccountId=' + retailPosAccountId,
      {
        retailPosAccountId,
      }
    );
    return data;
  },
};
