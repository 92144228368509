import * as Sentry from '@sentry/react';

export const authReducerInitialState = {
  loading: true,
  isTokenValid: false,
  authToken: undefined,
  user: null,
};
export function authReducer(state, action) {
  switch (action.type) {
    case 'auth/SET_IS_TOKEN_VALID':
      Sentry.setContext('isTokenValid', action.payload.isTokenValid);

      return {
        ...state,
        isTokenValid: action.payload.isTokenValid,
      };
    case 'auth/SET_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'auth/SET_USER':
      Sentry.setContext('user', action.payload.user);
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
}

const setLoading = (loading) => {
  return {
    type: 'auth/SET_LOADING',
    payload: {
      loading,
    },
  };
};

const setIsTokenValid = (isTokenValid) => {
  return {
    type: 'auth/SET_IS_TOKEN_VALID',
    payload: {
      isTokenValid,
    },
  };
};

const setUser = (user) => {
  return {
    type: 'auth/SET_USER',
    payload: {
      user,
    },
  };
};

export const authActions = {
  setLoading,
  setIsTokenValid,
  setUser,
};

export const authFactory = (data = {}) => {
  return Object.assign({}, authReducerInitialState, data);
};
