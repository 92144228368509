import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export function useNavigateWithQuery() {
  const navigate = useNavigate();
  const location = useLocation();
  return useCallback(
    (path, options) => {
      navigate({
        pathname: path,
        search: location.search,
      });
    },
    [location.search, navigate],
  );
}
