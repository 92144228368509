/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

export const ErrorScreen = (props) => {
  const { bannerProps } = props;

  return (
    <Header className="card">
      <h3>{bannerProps.title}</h3>

      <div>{bannerProps.description}</div>
    </Header>
  );
};
const Header = styled.div`
  padding: 24px;
  border-radius: 4px;
  background: #fff4f4;
  border: 1px solid #e0b3b2;
  margin: auto;
  margin-top: 20%;
  width: 40%;
  text-align: center;
`;
