import { AuthenticatedApi } from '../utils/Api';

export const RetailService = {
  async getCategories(accountName) {
    const { data } = await AuthenticatedApi.get(
      'api/1.0/enterprise-bevspot/category/' + accountName
    );
    return data;
  },
  async getClasses(accountName) {
    const { data } = await AuthenticatedApi.get(
      'api/1.0/enterprise-bevspot/class/' + accountName
    );
    return data;
  },
  async updateClassMapping(body) {
    const { data } = await AuthenticatedApi.post('/api/1.0/enterprise-bevspot/mapping/class', body);
    return data;
  },
  async getVendors(accountName) {
    const { data } = await AuthenticatedApi.get('api/1.0/enterprise-bevspot/vendor/' + accountName);
    return data;
  },
  async getAccounts() {
    const { data } = await AuthenticatedApi.get('/api/1.0/retailpos-account/account');
    return data;
  },
  async updateIntegration(body) {
    const { data } = await AuthenticatedApi.put('/api/1.0/enterprise-bevspot/account/update', body);
    return data;
  },
  async updateCategoryMapping(body) {
    const { data } = await AuthenticatedApi.post('/api/1.0/enterprise-bevspot/mapping/category', body);
    return data;
  },
  async updateVendorMapping(body) {
    const { data } = await AuthenticatedApi.post('/api/1.0/enterprise-bevspot/mapping/vendor', body);
    return data;
  },
  async disconnect(accountName) {
    const { data } = await AuthenticatedApi.put(
      '/api/1.0/enterprise-bevspot/disconnect/' + accountName
    );
    return data;
  },
};
