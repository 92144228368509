import axios from 'axios';
import * as Constant from '../utils/Constant';
import * as Sentry from '@sentry/react';
import { Storage } from './Storage';

export const AuthenticatedApi = axios.create({
  baseURL: '/',
});

AuthenticatedApi.interceptors.request.use(
  async (config) => {
    const headers = config.headers;
    if (!headers) {
      config.headers = {};
    }
    config.headers.Authorization = 'Session ' + Storage.get(Constant.SP_AUTH_TOKEN);
    return config;
  },
  (error) => Promise.reject(error)
);

function isAuthServiceLogoutResponse(response) {
  if (
    response?.status === 401
  ) {
    return true;
  }

  return false;
}

AuthenticatedApi.interceptors.response.use(
  (response) => {
    Sentry.addBreadcrumb({
      category: 'api',
      message: 'URL: ' + response.config.url + ' Response Code: ' + response.status,
      level: 'info',
    });
    return Promise.resolve(response);
  },
  (error) => {
    Sentry.addBreadcrumb({
      category: 'api',
      message:
        'URL: ' +
        error?.response?.config?.url +
        ' Response Code: ' +
        error?.response?.status +
        ' Data: ' +
        JSON.stringify(error?.response?.data || {}),
      level: 'error',
    });
    if (error.response.status !== 401 && error.response.status !== 503) {
      Sentry.captureException(error);
    }
    if (error && error.response && isAuthServiceLogoutResponse(error.response)) {
      Storage.set(Constant.SP_AUTH_TOKEN, '');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
export const Api = axios.create({
  baseURL: '/',
});







