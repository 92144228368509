import { useEffect } from 'react';
import { useGlobalState } from '../hooks/useGlobalState';
import { AuthService } from '../services/Auth.service';
import { authActions } from '../state/authState';

export async function useAuthCheck() {
  const { dispatch } = useGlobalState();
  useEffect(() => {
    (async () => {
      try {
        dispatch(authActions.setLoading(true));
        const user = await AuthService.getAuthenticatedUser();
        dispatch(authActions.setIsTokenValid(true));
        dispatch(authActions.setUser(user));
      } catch (error) {
        // Sentry.captureException(error);
        dispatch(authActions.setIsTokenValid(false));
      } finally {
        dispatch(authActions.setLoading(false));
      }
    })();
  }, []);
}
